<template>
  <div class="admin-index">
    <div class="admin-links">
      <router-link :to="{ name: 'ChallengeList' }" class="admin-link">
        <h2>Challenge Admin</h2>
        <p>Manage writing challenges</p>
      </router-link>

      <router-link :to="{ name: 'ChapterSearch' }" class="admin-link">
        <h2>Chapter Admin</h2>
        <p>Search and manage chapters</p>
      </router-link>

      <router-link :to="{ name: 'UnresolvedComplaints' }" class="admin-link">
        <h2>Unresolved Complaints</h2>
        <p>Review unresolved user complaints</p>
      </router-link>

      <router-link :to="{ name: 'UserSearch' }" class="admin-link">
        <h2>User Admin</h2>
        <p>Search and manage users</p>
      </router-link>

      <router-link :to="{ name: 'EventProblems' }" class="admin-link">
        <h2>Event Problems</h2>
        <p>Review reported event problems</p>
      </router-link>

    </div>
  </div>
</template>

<script>
export default {
  name: "AdminIndex"
};
</script>

<style scoped>
.admin-index {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

h1 {
  margin-bottom: 2rem;
  text-align: center;
}

.admin-links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.admin-link {
  display: block;
  padding: 1.5rem;
  border-radius: 8px;
  background: #f5f5f5;
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s, box-shadow 0.2s;
}

.admin-link:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-link h2 {
  margin: 0 0 0.5rem;
  font-size: 1.25rem;
}

.admin-link p {
  margin: 0;
  color: #666;
}
</style>
